<template>
  <div v-if="loading">
    <div class="spinner-border" role="status">


      <span class="visually-hidden">Loading...</span>

    </div>
  </div>
  <div v-else class="row">
    <div class="col-lg-8 col-xxxl-12">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing chat-card"
      >
        <div
          class="card-head box-shadow bg-white d-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
        >
          <div class="d-flex align-items-center">
            <img
              src="../../assets/images/albert.png"
              width="44"
              height="44"
              class="rounded-circle"
              alt="user"
            />
            <div class="ms-15">
              <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium"
                >Robot Albert Trainer</span
              >
<!--              <span class="d-block text-success">Active Now</span>-->
            </div>
          </div>
          <div class="dropdown">
            <button
              class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-dots"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i class="ph-bold ph-speaker-simple-slash me-8"></i> Mute Chat
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i class="ph-bold ph-trash me-8"></i> Delete
                </a>
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                >
                  <i class="ph-bold ph-warning-circle me-8"></i> Block
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body p-15 p-sm-20 p-md-25">
          <div class="chat-list" id="trainer-chat">
            <div class="chat-item info text-center position-relative">
              <span class="d-inline-block fs-12 text-muted bg-white ps-12 pe-12 lh-1">{{ activityData[0].title }}</span>
            </div>
            <template v-for="message in activityData[0].messages" v-bind:key="message">
              <div class="chat-item position-relative" v-if="(message.template.type === 'buttons_multiple' || message.template.type === 'survey_multiple') && message.visible">
                <div class="inner">
                  <p class="fs-md-15 fs-lg-16 lh-base">
                    {{ message.data.question.replace(/<\/?[^>]+(>|$)/g, "") }}
                  </p>
                </div>
                <div class="inner">
                  <div v-for="[answer_id,button] in Object.entries(message.data.cloneable)" v-bind:key="answer_id" class="inner">
                    <button
                      type="button"
                      class="btn btn-warning"
                      @click="submitAnswer(activityData[0].sessionId, message.id, message.activity_section_id, answer_id)"
                    >
                      {{ button.btn_text }} - {{ button.answer_text.replace(/<\/?[^>]+(>|$)/g, "") }}
                    </button>
                  </div>
                </div>
              </div>
              <div v-if="message.answer !== undefined" class="chat-item position-relative right">
                <div class="message position-relative">
                  <div class="inner">
                    <p class="fs-md-15 fs-lg-16 lh-base">
                      <b>Дан ответ: </b>{{ message.data.cloneable[message.answer.data].btn_text }}
                    </p>
                  </div>
                </div>
              </div>
            </template>
            <div v-if="activityFinalizationResult !== null && activityFinalizationResult !== undefined" class="chat-item position-relative">
              <div class="inner" v-html="activityFinalizationResult.activityResult">
              </div>
              <button
                  v-if="activityFinalAnswersWithComments === null || activityFinalAnswersWithComments === undefined"
                  type="button"
                  class="btn btn-warning"
                  @click="showCorrectAnswersAndComments(activityData[0].messages[0].activity_section_id)"
              >
                Показать ответы и комментарии
              </button>
              <div class="inner hidden" id="showResults" v-if="activityFinalAnswersWithComments !== null && activityFinalAnswersWithComments !== undefined">
                Правильные ответы и комментарии:
              </div>
            </div>
            <div v-if="activityFinalAnswersWithComments !== null && activityFinalAnswersWithComments !== undefined" class="chat-item position-relative">
              <div class="inner" v-html="activityFinalAnswersWithComments.activityAnswersAndComments">
              </div>
            </div>
            <div v-if="nextActivityAvailable && activityFinalizationResult.nextActivityId > 0" class="chat-item position-relative fa-align-center">
              <a class="btn btn-primary" :href="'/activity?courseId=' + courseId + '&activityId=' + activityFinalizationResult.nextActivityId + '&campaignId=' + campaignId" role="button">Продолжить</a>
            </div>
          </div>
<!--          <form>-->
<!--            <div class="write-your-message position-relative">-->
<!--              <div class="write-message position-relative">-->
<!--                <input-->
<!--                  type="text"-->
<!--                  class="input-message d-block w-130 text-black fs-14"-->
<!--                  placeholder="Type your message here"-->
<!--                />-->
<!--                <button-->
<!--                  type="button"-->
<!--                  class="bg-transparent p-0 border-0 transition"-->
<!--                >-->
<!--                  <i class="ph ph-smiley"></i>-->
<!--                </button>-->
<!--              </div>-->
<!--              <div class="buttons-list">-->
<!--                <button-->
<!--                  type="button"-->
<!--                  class="border-0 d-inline-block position-relative text-center transition active"-->
<!--                >-->
<!--                  <i class="flaticon-airplane"></i>-->
<!--                </button>-->
<!--              </div>-->
<!--            </div>-->
<!--          </form>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {LocationQuery, useRoute} from "vue-router";
import stateStore from "../../utils/store";
import router from "@/router";
import {authProvider} from "../../utils/auth";
import {defineComponent, ref} from "vue";

export default {
  name: "ActivityContent",
  data() {
    return {
      loading: true,
      activityData: {},
      externalServerUrl: process.env.VUE_APP_ALBERT_EXTERNAL_WEB_SERVER,
      courseId: NaN,
      activityId: NaN,
      campaignId: NaN,
      currentUserInfo: {},
      activityAnswers: {},
      activityFinalizationResult: null,
      activityFinalAnswersWithComments: null,
      nextActivityAvailable: false,
      query: ref<LocationQuery|null>(null)
    };
  },
  methods: {
    async showCorrectAnswersAndComments(activitySectionId) {
      const redirectUrl = `activity?courseId=${this.courseId}&activityId=${this.activityId}&campaignId=${this.campaignId}`;
      const activityAnswersAndCommentsUrl = `${this.externalServerUrl}/api/course/${this.courseId}/activity/${activitySectionId}/answers-and-comments`;
      await authProvider.authenticatedRequest('get', activityAnswersAndCommentsUrl, {}, redirectUrl, (response) => {
        this.activityFinalAnswersWithComments = response.data;
        // this.nextActivityAvailable = true;
      });
    },
    async getActivityAnswers() {
      const redirectUrl = `activity?courseId=${this.courseId}&activityId=${this.activityId}&campaignId=${this.campaignId}`;
      const activitySectionAnswersUrl = `${this.externalServerUrl}/api/course/activity/campaign/${this.campaignId}/course/${this.courseId}/section/${this.activityId}/answers`;
      await authProvider.authenticatedRequest('get', activitySectionAnswersUrl, {}, redirectUrl, (response) => {
        const answers = response.data['answers'];
        for (let i = 0; i < answers.length; i++) {
          this.activityAnswers[answers[i]['activity_message_id']] = {
            type: answers[i]['type'],
            data: answers[i]['data']
          };
        }
      });
    },
    async getCurrentUserInfo() {
      const currentUserInfoApiUrl = `${this.externalServerUrl}/api/user/me`;
      const redirectUrl = `activity?courseId=${this.courseId}&activityId=${this.activityId}&campaignId=${this.campaignId}`
      await authProvider.authenticatedRequest('get', currentUserInfoApiUrl, {}, redirectUrl, (response) => {
        this.currentUserInfo = response.data;
      });
    },
    async getActivity(courseId, activityId) {
      const redirectUrl = `activity?courseId=${courseId}&activityId=${activityId}&campaignId=${this.campaignId}`
      const isAuthenticated = await authProvider.isAuthenticated();
      let tokenRefreshResponse;
      if (!isAuthenticated) {
        tokenRefreshResponse = await authProvider.refreshAccessToken()
            .then((response) => {
              authProvider.setAuthState(response.data);
            }).catch((error) => {
              router.push({ name: "LoginPage" , query: { redirect: redirectUrl }});
              return error.response;
            });
      }
      if (tokenRefreshResponse === undefined || tokenRefreshResponse.status === 200) {
        const apiUrl = this.externalServerUrl;
        const {data} = await authProvider.axiosClient().get(`${apiUrl}/api/course/${courseId}/activity/${activityId}`);
        this.activityData = data;
        console.log(this.activityData);
      }
    },
    async mergeActivitiesWithAnswers() {
      for (let i = 0; i < this.activityData.length; i++) {
        const activity = this.activityData[i];
        const activityAnswers = this.activityAnswers;
        if (activityAnswers !== undefined) {
          let allMessagesHasAnswers = true;
          for (let j = 0; j < activity.messages.length; j++) {
            const message = activity.messages[j];
            if (activityAnswers[message.id] !== undefined) {
                message.answer = activityAnswers[message.id];
                allMessagesHasAnswers &= true;
            } else {
                allMessagesHasAnswers &= false;
            }
            if (activityAnswers[message.id] !== undefined || (j > 0 && activity.messages[j - 1].answer !== undefined)) {
                message.visible = true;
            }
          }
          if (allMessagesHasAnswers) {
            await this.finalizeActivity();
          }
        }
      }
    },
    async finalizeActivity() {
      const redirectUrl = `activity?courseId=${this.courseId}&activityId=${this.activityId}&campaignId=${this.campaignId}`
      await authProvider.authenticatedRequest('post', `${this.externalServerUrl}/api/course/activity/finalize`, {
        courseId: this.courseId,
        activitySectionId: this.activityId,
        sectionId: this.activityData[0].sectionId,
        sessionId: this.activityData[0].sessionId,
        campaignId: this.campaignId
      }, redirectUrl, async (response) => {
        this.activityFinalizationResult = response.data;
      });
    },
    async submitAnswer(sessionId, activityMessageId, activitySectionId, answerId) {
      const redirectUrl = `activity?courseId=${this.courseId}&activityId=${activitySectionId}&campaignId=${this.campaignId}`

      await authProvider.authenticatedRequest('post', `${this.externalServerUrl}/api/course/activity`, {
        courseId: this.courseId,
        campaignId: this.campaignId,
        sessionId,
        activityMessageId,
        activitySectionId,
        answerId
      }, redirectUrl, async (response) => {
        await this.getActivityAnswers();
        await this.mergeActivitiesWithAnswers();
        console.log(response);
      });
    }
  },
  async beforeMount() {
    const route = useRoute();
    this.query = route.query;
    this.courseId = route.query.courseId;
    this.activityId = route.query.activityId;
    this.campaignId = route.query.campaignId;
    await this.getActivityAnswers();
    await this.getCurrentUserInfo();
    if (this.currentUserInfo['data'] !== undefined) {
      if (this.currentUserInfo['data']['timing'] === undefined || this.currentUserInfo['data']['timing'] === null) {
        await authProvider.axiosClient().post(`${this.externalServerUrl}/api/course/activity/${this.activityId}`);
      } else if (this.currentUserInfo['data']['timing'] !== undefined && this.currentUserInfo['data']['timing'] !== null) {
        if (this.currentUserInfo['data']['timing'][this.activityId] === undefined) {
          await authProvider.axiosClient().post(`${this.externalServerUrl}/api/course/activity/${this.activityId}`);
        }

      }
    }
    await this.getActivity(this.courseId, this.activityId);
    await this.mergeActivitiesWithAnswers();
    this.loading = false;
  }
};
</script>
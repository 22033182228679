<template>
  <div class="card mb-25 border-0 rounded-0 welcome-box">
    <div class="card-body pe-15 pe-sm-20 pe-md-0 pb-0 pt-15 pt-sm-20">
      <div class="row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="title position-relative">
            <h3 class="fw-semibold mb-8">
              {{query}}
              С Добрым Утром!123 <span class="fw-bold">{{startapp}}</span>
            </h3>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 text-center mt-15 mt-md-0">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref} from "vue";
import {LocationQuery, useRoute} from "vue-router";
import router from "@/router";

export default defineComponent({
  name: "WhatHappening",
  data() {
    return {
      query: ref<LocationQuery|null>(null),
    };
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    login() {},
  },
  beforeMount() {
    const route = useRoute();
    const tgWebAppStartParam = JSON.parse(atob(route.query.tgWebAppStartParam as string));
    router.push({name: 'ActivityPage', query: {
        courseId: tgWebAppStartParam.courseId,
        activityId: tgWebAppStartParam.activityId,
        campaignId: tgWebAppStartParam.campaignId
      }
    });
  },
});
</script>
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7" }
const _hoisted_3 = { class: "col-xxl-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-lg-6 col-md-6" }
const _hoisted_6 = { class: "col-lg-6 col-md-6" }
const _hoisted_7 = { class: "col-xxl-6" }
const _hoisted_8 = { class: "col-xxl-6" }
const _hoisted_9 = { class: "col-xxl-3" }
const _hoisted_10 = { class: "col-xxl-6" }
const _hoisted_11 = { class: "col-xxl-3" }
const _hoisted_12 = { class: "col-xxl-8" }
const _hoisted_13 = { class: "col-xxl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_ResolveComplaints = _resolveComponent("ResolveComplaints")!
  const _component_AverageSpeedOfAnswer = _resolveComponent("AverageSpeedOfAnswer")!
  const _component_TicketsChart = _resolveComponent("TicketsChart")!
  const _component_NumberOfTickets = _resolveComponent("NumberOfTickets")!
  const _component_TopPerformerHelpAgent = _resolveComponent("TopPerformerHelpAgent")!
  const _component_UnresolvedTicketsByPriority = _resolveComponent("UnresolvedTicketsByPriority")!
  const _component_CustomerSatisfaction = _resolveComponent("CustomerSatisfaction")!
  const _component_UnsolvedTickets = _resolveComponent("UnsolvedTickets")!
  const _component_ToDoList = _resolveComponent("ToDoList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_WhatHappening)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ResolveComplaints)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_AverageSpeedOfAnswer)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_TicketsChart)
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_NumberOfTickets)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_TopPerformerHelpAgent)
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(_component_UnresolvedTicketsByPriority)
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_CustomerSatisfaction)
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_UnsolvedTickets)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createVNode(_component_ToDoList)
    ])
  ]))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-9" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-4" }
const _hoisted_5 = { class: "col-lg-4" }
const _hoisted_6 = { class: "col-lg-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-8" }
const _hoisted_9 = { class: "col-lg-4" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-lg-4" }
const _hoisted_12 = { class: "col-lg-8" }
const _hoisted_13 = { class: "row" }
const _hoisted_14 = { class: "col-lg-5" }
const _hoisted_15 = { class: "col-lg-7" }
const _hoisted_16 = { class: "col-xxl-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewContact = _resolveComponent("NewContact")!
  const _component_NewUsers = _resolveComponent("NewUsers")!
  const _component_NewLeads = _resolveComponent("NewLeads")!
  const _component_MostLead = _resolveComponent("MostLead")!
  const _component_ForBetterOutreach = _resolveComponent("ForBetterOutreach")!
  const _component_TopPerformer = _resolveComponent("TopPerformer")!
  const _component_RevenueTargetByCountry = _resolveComponent("RevenueTargetByCountry")!
  const _component_ToDoList = _resolveComponent("ToDoList")!
  const _component_RecentLeads = _resolveComponent("RecentLeads")!
  const _component_CallLeads = _resolveComponent("CallLeads")!
  const _component_CampaignsChart = _resolveComponent("CampaignsChart")!
  const _component_AverageCallDuration = _resolveComponent("AverageCallDuration")!
  const _component_ClientPaymentStatus = _resolveComponent("ClientPaymentStatus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_NewContact)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_NewUsers)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_NewLeads)
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_MostLead)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_ForBetterOutreach)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_TopPerformer)
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_RevenueTargetByCountry)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_ToDoList)
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_RecentLeads)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_16, [
      _createVNode(_component_CallLeads),
      _createVNode(_component_CampaignsChart),
      _createVNode(_component_AverageCallDuration),
      _createVNode(_component_ClientPaymentStatus)
    ])
  ]))
}
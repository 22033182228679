import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5a43c216"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-upload text-center position-relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "d-block text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      src: _ctx.imageUrl,
      alt: "Uploaded Image",
      class: "preview-image"
    }, null, 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _createTextVNode(" Drop Files Here Or "),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImage && _ctx.uploadImage(...args))),
        class: "text-black fw-medium position-relative"
      }, " Click To Upload ")
    ]),
    _createElementVNode("input", {
      type: "file",
      class: "d-block shadow-none border-0 position-absolute start-0 end-0 top-0 bottom-0 z-1 opacity-0",
      ref: "fileInput",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args)))
    }, null, 544)
  ]))
}
<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
<!--            Create Your Account-->
            Форма регистрации
          </h4>
          <form v-on:submit.prevent="registerUser">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Имя</label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: Василий"
                name="first_name"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">Фамилия</label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: Петров"
                name="last_name"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Адрес электронной почты (логин)
              </label>
              <input
                type="email"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: vasya.petor@evraz.ru"
                name="email"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Staff ID
              </label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: 346234"
                name="staff_id"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Временная зона
              </label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: -2"
                name="timezone"
                id="timezoneInput"
              />
              <label for="timezoneInput">относительно московской</label>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Telegram ID
              </label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: 3456456482"
                name="telegramId"
                required
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Telegram Ник
              </label>
              <input
                type="text"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: VasyaPetrov"
                name="telegramUserName"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Пароль
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                name="password"
                aria-describedby="validationPasswordFeedback1" required
              />
              <div id="validationPasswordFeedback1" class="invalid-feedback hidden">
                Пароли не совпадают
              </div>
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Повторите пароль
              </label>
              <input
                type="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                name="passwordConfirmation"
                aria-describedby="validationPasswordFeedback2" required
              />
              <div id="validationPasswordFeedback2" class="invalid-feedback hidden">
                Пароли не совпадают
              </div>
            </div>
            <div class="alert alert-warning alert-dismissible fs-md-15 fs-lg-16 d-none" role="alert" id="userExistsAlert">
              Пользователь с таким email уже существует
            </div>
            <div>
              <div
                  class="tab-pane fade show active"
                  id="contentModalPreview-tab-pane"
                  role="tabpanel"
                  tabindex="0"
              >
                <div
                    class="form-check form-check-primary mb-0 fs-md-15 fs-lg-16 text-muted lh-1 mb-15 mb-md-20"
                >
                  <input
                      class="form-check-input shadow-none"
                      type="checkbox"
                      id="accept-terms-and-conditions"
                      @change="agreementCheckboxChange($event)"
                  />
                  <label class="form-check-label" for="remember-me">
                    Согласен с
                    <!-- Button trigger modal -->
                    <a v-bind:href="userAgreementFileUrl" class="text-decoration-none" target="_blank">
                      условиями пользовательского соглашения
                    </a>
                  </label>
                </div>
              </div>
            </div>
            <button
                disabled
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-gray d-block w-100"
              type="submit"
            >
              Зарегистрироваться
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import axios from "axios";
import router from "@/router";

export default {
  name: "RegisterContent",
  data() {
    return {
      userAgreementFileUrl: "",
      loadingUserAgreementFileUrl: true,
      userRegistrationUrl: process.env.VUE_APP_ALBERT_EXTERNAL_WEB_SERVER + "/ext-api/user/registration",
    }
  },
  methods: {
    async registerUser() {
      const password = document.querySelector('input[name="password"]').value;
      const passwordConfirmation = document.querySelector('input[name="passwordConfirmation"]').value;
      document.getElementById('userExistsAlert').classList.add('d-none');
      document.querySelector('input[name="email"]').classList.remove('is-invalid');
      if (password !== passwordConfirmation) {
        console.log("Passwords do not match");
        document.getElementById('validationPasswordFeedback1').classList.remove('hidden');
        document.getElementById('validationPasswordFeedback2').classList.remove('hidden');
        document.querySelector('input[name="password"]').classList.add('is-invalid');
        document.querySelector('input[name="passwordConfirmation"]').classList.add('is-invalid');
        return;
      } else {
        document.getElementById('validationPasswordFeedback1').classList.add('hidden');
        document.getElementById('validationPasswordFeedback2').classList.add('hidden');
        document.querySelector('input[name="password"]').classList.remove('is-invalid');
        document.querySelector('input[name="passwordConfirmation"]').classList.remove('is-invalid');
        console.log("Passwords match");
        const firstName = document.querySelector('input[name="first_name"]').value;
        const lastName = document.querySelector('input[name="last_name"]').value;
        const email = document.querySelector('input[name="email"]').value;
        const telegramId = document.querySelector('input[name="telegramId"]').value;
        const telegramUserName = document.querySelector('input[name="telegramUserName"]').value;
        const staffId = document.querySelector('input[name="staff_id"]').value;
        const timezone = document.querySelector('input[name="timezone"]').value;
        const data = {
          firstName,
          lastName,
          email,
          password,
          passwordConfirmation,
          telegramId,
          telegramUserName,
          staffId,
          timezone
        }
        await axios.post(this.userRegistrationUrl, data, {
          validateStatus: function (status) {
            if (status === 201) {
              router.push({ name: "LoginPage" });
            }
            if (status === 409) {
              document.getElementById('userExistsAlert').classList.remove('d-none');
              document.querySelector('input[name="email"]').classList.add('is-invalid');
            }
            return true;
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    async fetchUserAgreementFileUrl() {
      const { data } = await axios.get("/ext-api/user/agreement", {
        mode: 'no-cors'
      });
      // const { data } = await axios.get(process.env.VUE_APP_ALBERT_EXTERNAL_WEB_SERVER + "/ext-api/user/agreement", {
      //   mode: 'no-cors'
      // });
      this.loadingUserAgreementFileUrl = false;
      this.userAgreementFileUrl = data.user_agreement_file;
    },
    agreementCheckboxChange(e) {
      console.log(e);
      const agreementCheckbox = document.querySelector('input#accept-terms-and-conditions');
      const registerButton = document.querySelector('button[type="submit"]');
      if (agreementCheckbox.checked) {
        registerButton.disabled = false;
        registerButton.classList.add('bg-success');
        registerButton.classList.remove('bg-gray');
      } else {
        registerButton.disabled = true;
        registerButton.classList.remove('bg-success');
        registerButton.classList.add('bg-gray');
      }
    }
  },
  async beforeMount() {
    const route = useRoute();
    const telegramId = route.query.telegramId;
    const telegramUsername = route.query.telegramUserName;
    const firstName = route.query.firstName;
    const lastName = route.query.lastName;
    this.fetchUserAgreementFileUrl().then(() => {
      if (telegramId !== undefined) {
        document.querySelector('input[name="telegramId"]').value = telegramId;
      }
      if (telegramUsername !== undefined) {
        document.querySelector('input[name="telegramUserName"]').value = telegramUsername;
      }
      if (firstName !== undefined) {
        document.querySelector('input[name="first_name"]').value = firstName;
      }
      if (lastName !== undefined) {
        document.querySelector('input[name="last_name"]').value = lastName;
      }
    });
  }
};
</script>
import axios, {AxiosInstance, AxiosResponse} from "axios";
import { useCookies } from "vue3-cookies";
import stateStore from "@/utils/store";
import router from "@/router";

interface UserLoginData {
    username: string;
    password: string;
    getUserName: () => string;
    getPassword: () => string;
}
interface TokenProvider {
    getTokenUrl: () => string;
    getAuthClientId: () => string;
    getAuthGrantType: () => string;
    getAuthScope: () => string;
    getAuthClientSecret: () => string;
}
interface AuthProvider {
  accessToken: string;
  login: (loginData: UserLoginData) => Promise<AxiosResponse<any>>;
  logout: () => void;
  isAuthenticated: () => Promise<boolean>;
  getAccessToken: () => string;
  getRefreshToken: () => string;
  setAccessToken: (token: string) => void;
  setRefreshToken: (token: string) => void;
  refreshAccessToken: () => Promise<AxiosResponse<any>>;
  getAuthState: () => any;
  setAuthState: (state: any) => void;
  onAuthStateChanged: (callback: (state: any) => void) => void;
  axiosClient: () => AxiosInstance;
  authenticatedRequest: (method: string, url: string, data: any, redirectUrl: string, callback: (response: AxiosResponse<any>) => void) => void;
}

class UserLoginData {

    username: string;
    password: string;
    constructor(username: string, password: string) {
        this.username = username;
        this.password = password;
    }

    getUserName = () => this.username;

    getPassword = () => this.password;

}

const userLoginInfo = (username, password) => {
    return new UserLoginData(username, password);
};

const pingUrl = process.env.VUE_APP_ALBERT_EXTERNAL_WEB_SERVER + "/ping";


const tokenProvider: TokenProvider = {
    getTokenUrl: () => {
        return process.env.VUE_APP_KEYCLOAK_AUTH_TOKEN_URL;
    },
    getAuthClientId: () => {
        return process.env.VUE_APP_KEYCLOAK_CLIENT_ID;
    },
    getAuthGrantType: () => {
        return process.env.VUE_APP_KEYCLOAK_GRANT_TYPE;
    },
    getAuthScope: () => {
        return process.env.VUE_APP_KEYCLOAK_SCOPE;
    },
    getAuthClientSecret: () => {
        return process.env.VUE_APP_KEYCLOAK_CLIENT_SECRET;
    },
};

const { cookies } = useCookies();

const authProvider: AuthProvider = {
    accessToken: "",
    login: (loginData: UserLoginData) => {
        return axios.post(tokenProvider.getTokenUrl(), {
            grant_type: tokenProvider.getAuthGrantType(),
            client_id: tokenProvider.getAuthClientId(),
            client_secret: tokenProvider.getAuthClientSecret(),
            username: loginData.getUserName(),
            password: loginData.getPassword(),
            scope: tokenProvider.getAuthScope(),
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    },
    logout: () => {
        console.log("logout");
    },
    isAuthenticated: async () => {
        const pingResponse = await authProvider.axiosClient().get(pingUrl).catch((error) => {
            console.log("It's time to refresh auth token", error);
            return error.response;
        });
        return pingResponse.status === 200;
    },
    getAccessToken: () => {
        return stateStore.getAccessToken();
    },
    getRefreshToken: () => {
        return stateStore.getRefreshToken();
    },
    setAccessToken: (token: string) => {
        authProvider.accessToken = token;
    },
    setRefreshToken: (token: string) => {
        console.log("setRefreshToken", token);
    },
    refreshAccessToken: async () => {
        return await authProvider.axiosClient().post(tokenProvider.getTokenUrl(), {
            grant_type: "refresh_token",
            client_id: tokenProvider.getAuthClientId(),
            client_secret: tokenProvider.getAuthClientSecret(),
            refresh_token: stateStore.getRefreshToken(),
        }, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    },
    getAuthState: () => {
        return {};
    },
    setAuthState: (state: any) => {
        stateStore.onLogin(state);
        authProvider.setAccessToken(state.access_token);
    },
    onAuthStateChanged: (callback: (state: any) => void) => {
        console.log("onAuthStateChanged", callback);
    },
    axiosClient: () => {
        const axiosClient = axios.create();
        const accessToken = authProvider.getAccessToken() != authProvider.accessToken && authProvider.accessToken != "" ?
            authProvider.accessToken : authProvider.getAccessToken();
        axiosClient.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${accessToken}`;
            return config;
        });
        return axiosClient;
    },
    authenticatedRequest: async (method: string, url: string, data: any, redirectUrl: string, callback: any) => {
        const isAuthenticated = await authProvider.isAuthenticated();
        let tokenRefreshResponse;
        if (!isAuthenticated) {
            tokenRefreshResponse = await authProvider.refreshAccessToken()
                .then((response) => {
                    authProvider.setAuthState(response.data);
                }).catch((error) => {
                    router.push({ name: "LoginPage" , query: { redirect: redirectUrl }});
                    return error.response;
                });
        }
        if (tokenRefreshResponse === undefined || tokenRefreshResponse.status === 200) {
            await authProvider.axiosClient().request({
                method: method,
                url: url,
                data: data,
            }).then((response) => {
                callback(response);
            }).catch((error) => {
                console.log("error", error);
            });
        }
    }
};
export {authProvider, userLoginInfo};
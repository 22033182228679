<template>
  <div v-if="loading">
    <div class="spinner-border" role="status">


      <span class="visually-hidden">Loading...</span>

    </div>
  </div>
  <div v-else
    class="card mb-25 border-0 rounded-0 bg-white letter-spacing course-details-card"
  >
    <div class="card-body p-10">
      <div
        class="bg-gradient ps-15 pe-15 pt-20 pb-20 ps-md-30 pe-md-30 pt-md-35 pb-md-35 ps-lg-40 pe-lg-40 pt-lg-50 pb-lg-50"
      >
        <div class="course-details-header">
          <h2 class="fw-bold text-white">
            {{ campaignData.course.title }}
          </h2>
          <p class="lh-base fs-md-15 fs-lg-16">
            {{
              campaignData.course.description
            }}
          </p>
        </div>
      </div>
    </div>
    <div class="pe-xxl-25 pe-xxxl-50">
      <div class="row">
        <div class="col-xxl-8 col-xxxl-9">
          <div class="pe-xxxl-10">
            <div class="card-body pt-0 pb-0 ps-10 pe-10">
              <div class="course-info bg-gray">
                <div class="row">
                  <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                    <div class="info-card position-relative">
                      <div
                        class="icon bg-success rounded-circle text-center position-absolute start-0 text-white"
                      >
                        <i class="flaticon-calendar"></i>
                      </div>
                      <span
                        class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                      >
                        Начало Курса:
                      </span>
                      <span class="fw-medium text-paragraph">{{campaignData.campaign.started_at}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                    <div class="info-card position-relative">
                      <div
                        class="icon bg-warning rounded-circle text-center position-absolute start-0 text-white"
                      >
                        <i class="flaticon-time"></i>
                      </div>
                      <span
                        class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                      >
                        Окончание Курса:
                      </span>
                      <span class="fw-medium text-paragraph">{{campaignData.campaign.deadline_at}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                    <div class="info-card position-relative">
                      <div
                        class="icon bg-danger rounded-circle text-center position-absolute start-0 text-white"
                      >
                        <i class="flaticon-calendar-2"></i>
                      </div>
                      <span
                        class="d-block text-black-emphasis fs-md-15 fs-lg-16 fw-bold mb-1"
                      >
                        Количество дней на прохождение:
                      </span>
                      <span class="fw-medium text-paragraph">{{campaignData.campaign.deadline_days}}</span>
                    </div>
                  </div>
                  <div class="col-sm-6 col-lg-3 col-xl-6 col-xxxl-3">
                    <ul class="nav nav-pills">
                      <li class="nav-item">
                        <a class="nav-link active" aria-current="page" target="_blank" v-bind:href=botUrl>Начать</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {useRoute} from "vue-router";

export default {
  name: "CourseDetails",
  data() {
    return {
      loading: true,
      botUrl: process.env.VUE_APP_ALBERT_HELPER_BOT_URL,
      campaignData: {}
    }
  },
  methods: {
    async getCampaignData(campaignId) {
      const { data } = await axios.get(process.env.VUE_APP_ALBERT_EXTERNAL_WEB_SERVER + `/ext-api/campaign/?id=${campaignId}`)
      this.campaignData = data;
      this.loading = false;
    }
  },
  beforeMount() {
    const route = useRoute()
    const campaignId = route.params.campaignId;
    this.getCampaignData(campaignId)
  }
};
</script>
<template>
  <div class="row justify-content-center">
    <div class="col-md-10 col-lg-8 col-xl-9 col-xxl-8 col-xxxl-6">
      <div class="card mb-25 border-0 rounded-0 bg-white authentication-card">
        <div class="card-body letter-spacing">
          <h4 class="text-black fw-bold mb-0 text-center">
            Введите логин и пароль для входа!
          </h4>
          <form @submit.prevent="login">
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Логин (адрес электронной почты)
              </label>
              <input
                type="email"
                name="login"
                v-model="username"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="пример: тут-почта@евраз.рф"
                ref="input"
              />
            </div>
            <div class="form-group mb-15 mb-sm-20 mb-md-25">
              <label class="d-block text-black fw-semibold mb-10">
                Пароль
              </label>
              <input
                type="password"
                name="password"
                v-model="password"
                class="form-control shadow-none rounded-0 text-black"
                placeholder="**************"
                ref="input"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-between mb-15 mb-md-20"
            >
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white rounded-1 fs-md-15 fs-lg-16 bg-success d-block w-100"
              type="button"
              @click="login"
            >
              Войти
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useRoute} from "vue-router";
import router from "@/router";
import {authProvider, userLoginInfo} from "../../../utils/auth";

export default {
  name: "LoginForm",
  data() {
    return {
      redirect: "/",
      username: '',
      password: ''
    }
  },
  methods: {
    async login() {
      await authProvider.login(userLoginInfo(this.username, this.password))
        .then((response) => {
          authProvider.setAuthState(response.data);
          router.push(this.redirect);
        }).catch((error) => {
          console.log(error);
        });
    }
  },
  beforeMount() {
    const route = useRoute();
    console.log(route.query.redirect);
    this.redirect = route.query.redirect !== undefined ? route.query.redirect : "/";
  }
};
</script>
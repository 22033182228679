import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-content d-flex flex-column transition overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainSidebar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode(_component_MainFooter)
    ])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-7 col-xxxl-8" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6 col-md-12" }
const _hoisted_5 = { class: "col-lg-6 col-md-12" }
const _hoisted_6 = { class: "col-xxl-5 col-xxxl-4" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col-lg-5 col-xxl-4" }
const _hoisted_9 = { class: "col-lg-7 col-xxl-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StatsItem = _resolveComponent("StatsItem")!
  const _component_TaskOverview = _resolveComponent("TaskOverview")!
  const _component_ProjectDistribution = _resolveComponent("ProjectDistribution")!
  const _component_RecentActivity = _resolveComponent("RecentActivity")!
  const _component_WorkingSchedule = _resolveComponent("WorkingSchedule")!
  const _component_ProjectStats = _resolveComponent("ProjectStats")!
  const _component_ToDoList = _resolveComponent("ToDoList")!
  const _component_ActiveTasks = _resolveComponent("ActiveTasks")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_StatsItem),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_TaskOverview),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_ProjectDistribution)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_RecentActivity)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_WorkingSchedule)
      ])
    ]),
    _createVNode(_component_ProjectStats),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_ToDoList)
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_ActiveTasks)
      ])
    ])
  ], 64))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-lg-6" }
const _hoisted_5 = { class: "col-lg-6" }
const _hoisted_6 = { class: "col-xxl-6" }
const _hoisted_7 = { class: "col-xxl-4" }
const _hoisted_8 = { class: "col-xxl-8" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-lg-6 col-xxxl-5" }
const _hoisted_11 = { class: "col-lg-6 col-xxxl-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_WhatHappening = _resolveComponent("WhatHappening")!
  const _component_TodaysCourse = _resolveComponent("TodaysCourse")!
  const _component_TodaysEvent = _resolveComponent("TodaysEvent")!
  const _component_AverageEnrollmentRate = _resolveComponent("AverageEnrollmentRate")!
  const _component_CategoriesCourses = _resolveComponent("CategoriesCourses")!
  const _component_StudentsProgress = _resolveComponent("StudentsProgress")!
  const _component_CoursesClass = _resolveComponent("CoursesClass")!
  const _component_FundamentalCourses = _resolveComponent("FundamentalCourses")!
  const _component_PerformanceProgress = _resolveComponent("PerformanceProgress")!
  const _component_ToDoList = _resolveComponent("ToDoList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_WhatHappening),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_TodaysCourse)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_TodaysEvent)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_AverageEnrollmentRate)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_CategoriesCourses),
        _createVNode(_component_StudentsProgress)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_CoursesClass),
        _createVNode(_component_FundamentalCourses),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_PerformanceProgress)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ToDoList)
          ])
        ])
      ])
    ])
  ], 64))
}
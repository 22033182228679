import { reactive } from "vue";
import axios, {AxiosInstance} from "axios";
import { useCookies } from "vue3-cookies";

const { cookies } = useCookies();

interface StateStore {
  open: boolean;
  auth: any;
  onChange: () => void;
  onLogin: (auth: any) => void;
  axiosClient: AxiosInstance;
  onLoad: () => void;
  getRefreshToken: () => string;
  getAccessToken: () => string;
}

const stateStore: StateStore = reactive({
  open: false,

  auth: {},
  axiosClient: axios.create(),
  onChange ()  {
    stateStore.open = !stateStore.open
  },
  onLogin (auth: any) {
    stateStore.auth = auth
    cookies.set("auth", JSON.stringify(auth));
  },
  getRefreshToken () {
    return stateStore.auth.refresh_token
  },
  getAccessToken () {
    return stateStore.auth.access_token
  },
  onLoad() {
    const auth = cookies.get("auth")
    if (auth) {
      stateStore.auth = auth
    }
  }
})

export default stateStore
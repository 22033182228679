import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-6 col-sm-6" }
const _hoisted_3 = { class: "col-lg-6 col-sm-6" }
const _hoisted_4 = { class: "col-lg-6 col-sm-6" }
const _hoisted_5 = { class: "col-lg-6 col-sm-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OnHoldTicket = _resolveComponent("OnHoldTicket")!
  const _component_DueTicketsToday = _resolveComponent("DueTicketsToday")!
  const _component_ClosedTickets = _resolveComponent("ClosedTickets")!
  const _component_AnsweredTickets = _resolveComponent("AnsweredTickets")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_OnHoldTicket)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_DueTicketsToday)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_ClosedTickets)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_AnsweredTickets)
    ])
  ]))
}